<template>
    <div class="">
        <div class="row">
            <div class="search-sender-names-container">
                <el-input placeholder="search sender ID" class="search_item" v-model="search">
                    <i slot="prefix" style="padding-bottom:1em;"
                        class="el-input__icon el-icon-search search-icon-element"></i>
                </el-input>
            </div>
            <div class="col-md-12" v-if="loading">
                <div class="row mt-5 pt-5">
                    <div class="col-md-12" v-loading="loading">

                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="!loading">
                <el-table :data="tableDataComputed" :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
                    style="width: 100%" :empty-text="'No Data Available'" height="52vh" v-loading="tableLoading">
                    <el-table-column label="Sender ID" prop="sender_id" width="">
                    </el-table-column>
                    <el-table-column label="Credit Used" prop="credit_used" width="">
                    </el-table-column>
                    <el-table-column label="Phone" prop="phone" width="">
                    </el-table-column>
                    <el-table-column label="Sent On" width="200">
                        <template slot-scope="scope">
                            <p>
                                {{ moment(scope.row.created_at).format('ll') }}
                            </p>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                                                                        label="Action"
                                                                        width="200">
                                                                        <template slot-scope="scope">
                                                                            <button id="enter__account" class="approve" @click="publish(scope)" >
                                                                                Publish
                                                                            </button>
                                                                            <button class="text-danger" @click="deleteNotification(scope)">
                                                                                Delete
                                                                            </button>
                                                                        </template>
                                                                    </el-table-column> -->
                </el-table>
                <div class="col-md-12" style="text-align: center; margin-top:2em;">
                    <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                        :page-size="pageSize" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import store from '@/state/store.js'
import appConfig from '@/app.config'
export default {
    page: {
        title: 'Sender Names reports',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    data() {
        return {
            loading: false,
            page: 1,
            pageSize: 50,
            total: 0,
            search: '',
            tableData: [],
            tableLoading: false,
        }
    },
    computed: {
        tableDataComputed() {
            return this.tableData
        },
        searchingTableData() {
            if (!this.search) {
                this.total = this.tableData.length;
                return this.tableData;
            }
            this.page = 1;
            return this.tableData.filter(data => data.sender_id && data.sender_id.toLowerCase().includes(this.search.toLowerCase()) || data.username && data.username.toLowerCase().includes(this.search.toLowerCase()));
        },
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
            this.fetchData(val);
        },
        fetchData(page = 1) {
            this.tableLoading = true
            store.dispatch('admin/reporting/getSenderNamesReport', { page })
                .then((data) => {
                    this.tableData = data.data
                    this.total = data.total
                })
                .finally(() => this.tableLoading = false)
        }
    },
    mounted() {
        this.fetchData()
    }
}
</script>
<style scoped>
.text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484A4F;
}

.__message_text {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #8A8C8F;
    padding: 1em 0;
}

.search-sender-names-container {
    margin: 2rem 0;
}
</style>